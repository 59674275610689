<template>
  <v-container>
  <v-card class="mx-auto" elevation="2" v-if="pregunta < 0" :key="'p-' + pregunta">
    <v-toolbar color="#009EE3" dark>
      <v-toolbar-title>Calcula tus gastos</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
    Por favor llena el siguiente formulario para ayudarte a visualizar el estatus actual de tus finanzas. Debes de ingresar los montos gastados por mes a no ser que se indique un periodo distinto.
    </v-card-text>
    <v-card-actions class="text-center">
        <v-spacer></v-spacer>
        <v-btn color="success" @click="pregunta += 1">Comenzar</v-btn>
    </v-card-actions>
  </v-card>
  <v-card class="mx-auto" elevation="2" v-else-if="pregunta < 18">
    <v-toolbar :color="preguntas[pregunta].color" dark>
      <v-toolbar-title>{{preguntas[pregunta].titulo}}</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
        <v-row v-if="preguntas[pregunta].mensaje">
            <v-col>
            {{preguntas[pregunta].mensaje}}
            </v-col>
        </v-row>
        <v-form>
            <div v-for="(p, i) in preguntas[pregunta].preguntas" :key="'p-' + i">
                <v-text-field v-model="p.cantidad" :type="preguntas[pregunta].tipo" :label="p.titulo" clearable></v-text-field>
            </div>
        </v-form>
    </v-card-text>
    <v-card-actions class="text-center">
        <v-btn color="grey" @click="pregunta -= 1" v-if="pregunta > 0" class="mx-2" fab dark small><v-icon dark>mdi-arrow-left</v-icon></v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="continuar" class="mx-2" fab dark small><v-icon dark>mdi-arrow-right</v-icon></v-btn>
    </v-card-actions>
  </v-card>
  <v-card class="mx-auto" elevation="2" v-else>
    <v-toolbar color="success" dark>
      <v-toolbar-title>Resultados</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
        <v-row>
            <v-col>
                <p>Estos son tus resultados:</p>
                <p><strong>Egresos: ${{ egresos.toLocaleString() }}</strong></p>
                <chart width="300" height="300"
                id="chart"
                type="bar"
                title="Egresos"
                :labels="tipoEgresos"
                :data="montosEgresos"
                :border-color="'rgba(255, 222, 0, 1)'"
                :background-color="'rgba(255, 222, 0, .4)'"
                border-width="1"
                fill="true"></chart>
                <!-- <ul>
                    <li v-for="(p, i) in soloIngresos" :key="'eg-' + i">
                        <strong>{{p.titulo}}:</strong> ${{ p.total.toLocaleString() }}
                    </li>
                </ul> -->
                <p></p>
                <p><strong>Ingresos: ${{ ingresos.toLocaleString() }}</strong></p>
            </v-col>
        </v-row>
    </v-card-text>
  </v-card>
  </v-container>
</template>

<script>
// @ is an alias to /src
import Chart from '@/components/Chart.vue'

export default {
  name: 'Calcula-tus-gastos',
  data: () => ({
    //
    pregunta: -1,
    ingresos: 0,
    egresos: 0,
    preguntas: [ 
        {  "titulo": "Casa",
           "tipo": "number",
           "mensaje": "",
           "color": "#009EE3",
           "total": 0,
           "preguntas": [
                { "titulo": "Agua", "cantidad": 0, "factor": 1},
                { "titulo": "Luz bimestral", "cantidad": 0, "factor": .5},
                { "titulo": "Gas bimestral", "cantidad": 0, "factor": .5},
                { "titulo": "Cuota de mantenimiento fraccionamiento o torre de departamentos", "cantidad": 0, "factor": 1},
                { "titulo": "Jardinería", "cantidad": 0, "factor": 1},
                { "titulo": "Ayuda doméstica semanal", "cantidad": 0, "factor": 4},
                { "titulo": "Internet y televisión por cable", "cantidad": 0, "factor": 1},
                { "titulo": "Lavandería y tintorería semanal", "cantidad": 0, "factor": 4},
                { "titulo": "Seguro de casa", "cantidad": 0, "factor": 1}
           ]
        },
        {  "titulo": "Alimentos",
           "tipo": "number",
           "mensaje": "",
           "color": "#009EE3",
           "total": 0,
            "preguntas": [
                { "titulo": "Súper semanal", "cantidad": 0, "factor": 4},
                { "titulo": "Garrafones de agua semanal", "cantidad": 0, "factor": 4},
                { "titulo": "Alimento para mascotas", "cantidad": 0, "factor": 1},
                { "titulo": "Comidas fuera de casa", "cantidad": 0, "factor": 1},
                { "titulo": "Comida a domicilio semanal", "cantidad": 0, "factor": 4}
           ]
        },
        {  "titulo": "Uso Personal",
            "tipo": "number",
            "mensaje": "",
            "color": "#009EE3",
            "total": 0,
            "preguntas": [
                { "titulo": "Ropa", "cantidad": 0, "factor": 1},
                { "titulo": "Calzado", "cantidad": 0, "factor": 1},
                { "titulo": "Bolsas", "cantidad": 0, "factor": 1},
                { "titulo": "Joyería", "cantidad": 0, "factor": 1},
                { "titulo": "Relojes", "cantidad": 0, "factor": 1},
                { "titulo": "Cosméticos", "cantidad": 0, "factor": 1},
                { "titulo": "Maquillaje", "cantidad": 0, "factor": 1}
           ]
        },
        {  "titulo": "Cuidado Personal",
            "tipo": "number",
            "mensaje": "",
            "color": "#009EE3",
            "total": 0,
            "preguntas": [
                { "titulo": "Corte de cabello", "cantidad": 0, "factor": 1},
                { "titulo": "Tinte cabello", "cantidad": 0, "factor": 1},
                { "titulo": "Masajes", "cantidad": 0, "factor": 1},
                { "titulo": "Tratamiento de belleza", "cantidad": 0, "factor": 1},
                { "titulo": "Manicure", "cantidad": 0, "factor": 1},
                { "titulo": "Pedicure", "cantidad": 0, "factor": 1},
                { "titulo": "Depilación", "cantidad": 0, "factor": 1}
           ]
        },
        {  "titulo": "Salud",
            "tipo": "number",
            "mensaje": "",
            "color": "#009EE3",
            "total": 0,
            "preguntas": [
                { "titulo": "Poliza de gastos médicos mayores", "cantidad": 0, "factor": 1},
                { "titulo": "Dentista", "cantidad": 0, "factor": 1},
                { "titulo": "Lentes", "cantidad": 0, "factor": 1},
                { "titulo": "Gastos médicos que no cubra la póliza como medicinas, exámenes médicos, tratamientos y/o consultas", "cantidad": 0, "factor": 1},
                { "titulo": "Terapias de rehabilitación no incluidas en una póliza de gastos médicos y/o seguro social", "cantidad": 0, "factor": 1},
                { "titulo": "Terapia psicólogo, psicoterapeuta u otro", "cantidad": 0, "factor": 1}
           ]
        },
        {  "titulo": "Ahorros Personales e Inversiones",
            "tipo": "number",
            "mensaje": "",
            "color": "#009EE3",
            "total": 0,
            "preguntas": [
                { "titulo": "Ahorro mensual en cualquier institución", "cantidad": 0, "factor": 1},
                { "titulo": "Inversiones", "cantidad": 0, "factor": 1},
                { "titulo": "Caja de ahorro de la Empresa donde trabajas", "cantidad": 0, "factor": 1}
           ]
        },
        {  "titulo": "Movilidad",
            "tipo": "number",
            "mensaje": "",
            "color": "#009EE3",
            "total": 0,
            "preguntas": [
                { "titulo": "Gasolina semanal", "cantidad": 0, "factor": 4},
                { "titulo": "Mantenimiento en la agencia ó taller, anual", "cantidad": 0, "factor": .08333},
                { "titulo": "Gasto en algún otro tipo de transporte semanal", "cantidad": 0, "factor": 4},
                { "titulo": "Seguro de auto", "cantidad": 0, "factor": 1}
           ]
        },
        {  "titulo": "Créditos",
            "tipo": "number",
            "mensaje": "",
            "color": "#009EE3",
            "total": 0,
            "preguntas": [
                { "titulo": "Mensualidad crédito de casa", "cantidad": 0, "factor": 1},
                { "titulo": "Mensualidad crédito o leasing de auto", "cantidad": 0, "factor": 1},
                { "titulo": "Mensualidad tarjetas de crédito", "cantidad": 0, "factor": 1},
                { "titulo": "Mensualidad crédito personal", "cantidad": 0, "factor": 1}
           ]
        },
        {  "titulo": "Educación",
            "tipo": "number",
            "mensaje": "",
            "color": "#009EE3",
            "total": 0,
            "preguntas": [
                { "titulo": "Colegiaturas", "cantidad": 0, "factor": 1},
                { "titulo": "Inscripción anual", "cantidad": 0, "factor": .08333},
                { "titulo": "Uniformes, útiles y material escolar anual", "cantidad": 0, "factor": .08333},
                { "titulo": "Diplomados y cursos", "cantidad": 0, "factor": 1},
                { "titulo": "Maestría", "cantidad": 0, "factor": 1},
                { "titulo": "Doctorado", "cantidad": 0, "factor": 1},
                { "titulo": "Ahorro para la universidad de los hijos", "cantidad": 0, "factor": 1}
           ]
        },
        {  "titulo": "Seguros",
            "tipo": "number",
            "mensaje": "",
            "color": "#009EE3",
            "total": 0,
            "preguntas": [
                { "titulo": "Seguro de vida puro", "cantidad": 0, "factor": 1},
                { "titulo": "Seguro de vida con ahorro", "cantidad": 0, "factor": 1}
           ]
        },
        {  "titulo": "Apps",
            "tipo": "number",
            "mensaje": "",
            "color": "#009EE3",
            "total": 0,
            "preguntas": [
                { "titulo": "Ejercicio", "cantidad": 0, "factor": 1},
                { "titulo": "Música", "cantidad": 0, "factor": 1},
                { "titulo": "Películas y entretenimiento", "cantidad": 0, "factor": 1},
                { "titulo": "Juegos", "cantidad": 0, "factor": 1},
                { "titulo": "Compras como Amazon, Mercado Libre", "cantidad": 0, "factor": 1},
                { "titulo": "Libros en línea", "cantidad": 0, "factor": 1},
                { "titulo": "Deportes", "cantidad": 0, "factor": 1}
           ]
        },
        {  "titulo": "Recreación",
            "tipo": "number",
            "mensaje": "",
            "color": "#009EE3",
            "total": 0,
            "preguntas": [
                { "titulo": "Club Familiar", "cantidad": 0, "factor": 1},
                { "titulo": "Gym", "cantidad": 0, "factor": 1},
                { "titulo": "Cine", "cantidad": 0, "factor": 1},
                { "titulo": "Club Deportivo", "cantidad": 0, "factor": 1},
                { "titulo": "Viajes", "cantidad": 0, "factor": 1},
                { "titulo": "Conciertos", "cantidad": 0, "factor": 1},
                { "titulo": "Teatro", "cantidad": 0, "factor": 1},
                { "titulo": "Eventos Deportivos", "cantidad": 0, "factor": 1},
                { "titulo": "Clases de cualquier otra actividad ej. natación, baile, etc...", "cantidad": 0, "factor": 1},
           ]
        },
        {  "titulo": "Hobbies Deportivos",
            "tipo": "number",
            "mensaje": "",
            "color": "#009EE3",
            "total": 0,
            "preguntas": [
                { "titulo": "Inscripción y kit de maratones", "cantidad": 0, "factor": 1},
                { "titulo": "Inscripción, kit y gastos triatlones", "cantidad": 0, "factor": 1},
                { "titulo": "Inscripción, kit y gastos IronMan", "cantidad": 0, "factor": 1},
                { "titulo": "Algún otro deporte que se practique de forma profesional", "cantidad": 0, "factor": 1}
           ]
        },
        {  "titulo": "Regalos",
            "tipo": "number",
            "mensaje": "",
            "color": "#009EE3",
            "total": 0,
            "preguntas": [
                { "titulo": "Regalos por aniversarios y cumpleaños", "cantidad": 0, "factor": 1},
           ]
        },
        {  "titulo": "Donativos",
            "tipo": "number",
            "mensaje": "",
            "color": "#009EE3",
            "total": 0,
            "preguntas": [
                { "titulo": "Donativos asociaciones civiles", "cantidad": 0, "factor": 1},
                { "titulo": "Diezmo", "cantidad": 0, "factor": 1},
                { "titulo": "Donativos en especie", "cantidad": 0, "factor": 1}
           ]
        },
        {  "titulo": "Otros",
            "tipo": "number",
            "mensaje": "",
            "color": "#009EE3",
            "total": 0,
            "preguntas": [
                { "titulo": "Otros", "cantidad": 0, "factor": 1},
           ]
        },
        {  "titulo": "Ingresos",
            "tipo": "number",
            "mensaje": "Ahora escribe tu ingreso mensual.",
            "color": "success",
            "total": 0,
            "preguntas": [
                { "titulo": "Ingreso", "cantidad": 0, "factor": 1},
           ]
        },
        {  "titulo": "Datos personales",
            "tipo": "text",
            "mensaje": "Para poder generar tu reporte, por favor captura tus datos personales.",
            "color": "#009EE3",
            "total": 0,
            "preguntas": [
                { "titulo": "Nombre completo", "cantidad": ''},
                { "titulo": "Empresa en la que trabajas", "cantidad": ''},
                { "titulo": "Puesto", "cantidad": ''},
                { "titulo": "Correo electrónico", "cantidad": ''},
                { "titulo": "Celular", "cantidad": ''},
           ]
        },
      ]
  }),
  methods: {
      'continuar': function(){
          var este = this;
          este.ingresos = 0;
          este.egresos = 0;
          este.preguntas.forEach(function(it, ind){
              este.preguntas[ind].total = 0;
              it.preguntas.forEach(function(item, index){
                  if(ind < (este.preguntas.length -2)){
                      if(item.cantidad < 1){
                          este.preguntas[ind].preguntas[index].cantidad = 0;
                      } else {
                          este.egresos += (parseFloat(item.cantidad) * parseFloat(item.factor));
                          este.preguntas[ind].total += (parseFloat(item.cantidad) * parseFloat(item.factor));
                      }
                  } else if(ind == (este.preguntas.length -2)){
                      este.ingresos = item.cantidad;
                      este.preguntas[ind].total += item.cantidad;
                  } else if(ind == (este.preguntas.length - 1)){
                      if(este.pregunta == ind){
                          switch(index){
                              case 0:
                              case 3:
                                if(item.cantidad == ''){
                                    return true;
                                }
                              break;
                              case 1:
                              case 2:
                              break;
                              case 4:
                                if(item.cantidad == ''){
                                    return true;
                                } else {
                                    este.pregunta += 1;
                                }
                              break;
                          }
                      } else {
                          if(index == (it.preguntas.length - 1)){
                              este.pregunta += 1;
                          }
                      }
                  }
              });
          });
      },
  },
  computed: {
      soloIngresos: function() {
          var este = this;
          return this.preguntas.filter(function(it, ind){
              return ind < (este.preguntas.length - 2)
          });
      },
      montosEgresos: function(){
          var este = this;
          var eg = [];
          este.preguntas.forEach(function(it, ind){
              if(ind < (este.preguntas.length - 2)){
                  eg.push(it.total);
              }
          });
          return eg;
      },
      tipoEgresos: function(){
          var este = this;
          var eg = [];
          este.preguntas.forEach(function(it, ind){
              if(ind < (este.preguntas.length - 2)){
                  eg.push(it.titulo);
              }
          });
          return eg;
      },
      colores: function(){
          var este = this;
          var eg = [];
          este.preguntas.forEach(function(it, ind){
              if(ind < (este.preguntas.length - 2)){
                  eg.push(it.color);
              }
          });
          return eg;
      },
  },
  components: {
    Chart
  }
}
</script>
